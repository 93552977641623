<template>
  <div>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="'primary'"
          class="mr-1 capture-btn mb-1"
          :disabled="actionObj.IsLegalHold || (userType === 'Investigator' && Number(UID) !== Number(activityObj.UserID)) || readOnly"
          @click.prevent="uploadActivityThumbnail"
        >
          Upload Activity Thumbnail
        </b-button>
      </b-col>
    </b-row>
    <b-row class="white-bg-container">
      <b-col
        v-for="item in Videos"
        :key="item.FileID"
        md="3"
      >
        <div class="video-container">
          <div
            v-if="!readOnly"
            class="basket"
            @click="deleteModal(item.FileID)"
          >
            <img
              src="../../../assets/images/logo/redBasket.png"
              alt="basket"
            >
          </div>
          <img :src="item.URL">
          <div class="cursor-pointer" @click="selectDescription(item)">
            <div v-if="!item.Description">Add Caption Text…</div>
            <div v-else class="description-text">{{item.Description}}</div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="modal-change-description"
      ok-variant="danger"
      ok-title="Save"
      cancel-title="Cancel"
      modal-class="modal-primary"
      hide-header-close
      centered
      title="Update Caption"
      @ok="updateCaption"
      @hide="onHide"
    >
      <textarea
        class="textarea-block"
        v-model="Description"
        placeholder="Add Caption Text…"
        contentEditable
      />
    </b-modal>

    <b-modal
      v-model="modalShow"
      hide-backdrop
      size="md"
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>
              You are about to delete media.
            </strong>
          </h2>
          <div class="p-2">
            This action cannot be undone, are you sure you wish to continue?
          </div>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-danger"
              size="md"
              @click="chancelDeleteMedia()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="danger"
              class="mr-1"
              size="md"
              :disabled="(userType === 'Investigator' && Number(UID) !== Number(activityObj.UserID)) || actionObj.IsLegalHold || readOnly"
              @click="deleteMedia()"
            >
              Delete
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {BButton, BCol, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    activityObj: {
      type: Object,
      default: () => {},
    },
    actionObj: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      Videos: [],
      modalShow: false,
      deletedFile: "",
      userType: "",
      UID: "",
      fileToChange: null,
      Description: "",

      readOnly: !navigator.onLine,
    }
  },
  computed: {
    ...mapGetters({
      getUserType: "auth/getUserType",
      getOnlineStatus: "offline/getOnlineStatus",
    }),
  },
  watch: {
    getOnlineStatus(val) {
      this.readOnly = !val;
    },
  },
  mounted() {
    this.userType = localStorage.getItem("userType");
    this.UID = localStorage.getItem("UID");
  },
  created() {
    this.getVideos();
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    getVideos() {
      apiService
          .get("activity/" + this.activityObj.ActivityID + "/media-library")
          .then(res => {
            this.Videos = res.data;
          })
    },

    selectDescription(item) {
      if (this.readOnly) return;
      this.fileToChange = item;
      this.Description = this.fileToChange.Description || ""
      this.$bvModal.show("modal-change-description");
    },

    onHide() {
      this.fileToChange = null;
      this.Description = "";
      this.$bvModal.hide("modal-change-description");
      this.getVideos();
    },

    updateCaption() {
      const postData = {
        fileID: this.fileToChange.FileID,
        description: this.Description
      }
      apiService
          .put("file/", postData)
          .then(res => {
            if (res && res.status && res.status === 200) {
              this.showToast('success', 'top-center', 4000, 'Caption text has been saved!');
            } else {
              this.showToast('danger', 'top-center', 4000, 'Caption text has not been saved!');
            }
            this.fileToChange = null;
            this.Description = "";
            this.$bvModal.hide("modal-change-description");
            this.getVideos();
          })
    },

    uploadActivityThumbnail() {
      this.$router.push('/cases/upload-activity-thumbnail/' + this.activityObj.ActionID + '/' + this.activityObj.ActivityID);
    },

    chancelDeleteMedia() {
      this.modalShow = false;
      this.deletedFile = "";
    },

    deleteMedia() {
      apiService
          .delete("file/" + this.deletedFile)
          .then(() => {
            this.getVideos();
            this.chancelDeleteMedia();
          })
    },

    deleteModal(val) {
      this.modalShow = true;
      this.deletedFile = val;
    },
  },
}
</script>

<style scoped>
.video-container img {
  width: inherit;
  height: inherit;
}
.video-container {
  display: inline-block;
  height: 9rem;
  width: 16rem;
  position: relative;
  top: -0.5rem;
  margin-bottom: 3rem;
}
.basket {
  position: absolute;
  top: -1rem;
  right: -1rem;
  cursor: pointer;
  width: fit-content;
}
.white-bg-container {
  margin: 0;
}
.textarea-block {
  width: 100%;
  resize: none;
  padding: 5px 35px 5px 5px;
  border: 1px solid #d8d6de;
  border-radius: 5px;
  min-height: 8rem;
  overflow: auto;
  transition: all 500ms;
}
.description-text {
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2em;
  white-space: nowrap;
  padding: 0 0.5rem;
  position: absolute;
  width: 100%;
}
.description-text:hover {
  padding: 0.5rem;
  text-overflow: clip;
  overflow: auto;
  white-space: normal;
  position: absolute;
  background: white;
  height: auto;
  max-height: 25rem;
  z-index: 1;
  transition-duration: 0.8s;
  transform: translateY(-25%);
}
</style>