<template>
  <div>
    <b-row>
      <b-col md="3">
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            :variant="'primary'"
            class="mr-1"
            :disabled="(userType === 'Investigator' && Number(UID) !== Number(activityObj.UserID)) || readOnly"
            @click.prevent="uploadVideo()"
          >
            Upload Video
          </b-button>
        </div>
      </b-col>
      <b-col v-if="getCurrentScopes.indexOf('st2.editvideoproperties') !== -1">
        <div class="d-flex">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              :variant="'primary'"
              class="mr-1"
              :disabled="readOnly"
              @click.prevent="makeAllClipsVisible()"
            >
              Make All Clips Visible
            </b-button>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              :variant="'primary'"
              class="mr-1"
              :disabled="readOnly"
              @click.prevent="makeAllClipsHidden()"
            >
              Make All Clips Hidden
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <b-row class="p-1">
      <b-col class="min-height-10 rounded-sm">
        <b-row
          class="height-50 d-flex align-items-center"
        >
          <b-col
            class="text-center"
            md="6"
          >
            Clip/View
          </b-col>
          <b-col md="2">
            Duration
          </b-col>
          <b-col md="3">
            Info
          </b-col>
          <b-col md="1">
            Action
          </b-col>
        </b-row>
        <draggable
          v-model="Videos"
          @change="handleDg"
        >
          <b-row
            v-for="(item, index) of Videos"
            :key="index"
            class="d-flex align-items-center"
            :class="{'not-visible': !item.IsPublished}"
          >
            <b-col>
              <b-row class="pt-1 border-top">
                <b-col md="6">
                  <span class="text-nowrap">
                    <div class="video-content">
                      <img :src="'https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=' + item.VideoID">
                    </div>
                    <div class="video-content-text">{{ item.Title }}</div>
                    <div class="video-content-text-2">Clip: {{ item.Meta.Clip_Order }}</div>
                  </span>
                </b-col>
                <b-col md="2">
                  <span class="text-nowrap">{{ item.DurationMs | minutesFormat }}</span>
                </b-col>
                <b-col md="3">
                  <div class="text-nowrap">
                    {{ item.IsPublished | IsHidden }}
                  </div>
                  <span class="text-nowrap">Uploaded by {{ item.UploadedBy }}</span>
                  <span> on {{ item.CreatedDate | dateFormat }}</span>
                </b-col>
                <b-col md="1">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item>
                        <span @click="makeVisible(item.VideoID, !item.IsPublished)">{{ !item.IsPublished ? "Make Visible" : "Hide Video" }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <span @click="changeTitle(item.VideoID, item.Title)">{{ isIntegrity(item.Title) ? "Unmark Integrity" : "Mark Integrity" }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </draggable>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label="Nimbus Key"
          label-for="nimbusKey"
        >
          <div
            class="collapse-textarea"
            :class="{expanded: !isExpanded}"
          >
            <textarea
              v-model="NimbusKey"
              class="expandable-textarea"
              :class="[isExpanded ? 'not-expanded' : 'expanded']"
              contentEditable
              :readonly="true"
            />
            <div @click="isExpanded = !isExpanded">
              <span
                class="expand-arrow mt-n1"
                :class="[isExpanded ? 'not-expanded' : 'expanded']"
              >&#187;</span>
            </div>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BDropdown, BDropdownItem, BFormGroup, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";
import draggable from 'vuedraggable'

const apiService = new APIService();

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    draggable,
  },
  directives: {
    Ripple,
  },
  filters: {
    IsHidden(val) {
      return val ? "Visible" : "Hidden"
    },
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
      const Time = date.toLocaleTimeString("en-US", timeOptions);
      if (date) return date.toLocaleDateString("en-US", options) + " " + Time;
    },
    minutesFormat(val) {
      const minutes = Math.floor(val / 1000 / 60);
      const seconds = Math.floor(val / 1000 ) - minutes * 60;
      const minutesTxt = minutes === 1 ? " minute " : " minutes "
      const secondsTxt = minutes === 1 ? " second " : " seconds "
      return minutes + minutesTxt + seconds + secondsTxt;
    },
  },
  props: {
    activityObj: {
      type: Object,
      default: () => {},
    },
    actionObj: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      Videos: [],
      NimbusKey: "",
      userType: "",
      UID: "",
      isExpanded: false,

      readOnly: !navigator.onLine,
    }
  },
  computed: {
    ...mapGetters({
      getUserType: "auth/getUserType",
      getCurrentScopes: "scopes/getCurrentScopes",
      getOnlineStatus: "offline/getOnlineStatus",
    }),
  },
  watch: {
    getOnlineStatus(val) {
      this.readOnly = !val;
    },
  },
  mounted() {
    this.userType = localStorage.getItem("userType");
    this.UID = localStorage.getItem("UID");
  },
  async created() {
    try {
      this.getVideo();
      this.getNimbusKey();
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    uploadVideo() {
      window.open('/cases/upload-video-clip/' + this.activityObj.ActivityID, '_blank');
    },

    getVideo() {
      apiService
          .get("activity/" + this.activityObj.ActivityID + "/videos")
          .then(res => {
            this.Videos = res.data;
          })
    },

    getNimbusKey() {
      if (this.activityObj.ActivityID) {
        apiService
            .get("file/nimbus-downloader-key/" + this.activityObj.ActivityID)
            .then(res => {
              this.NimbusKey = res.data.NimbusKey;
            })
      }
    },

    makeAllClipsVisible() {
      apiService
          .post("action/" + this.activityObj.ActionID + "/videos/publish-all")
          .then(() => {
            this.getVideo();
          })
    },

    makeAllClipsHidden() {
      apiService
          .post("action/" + this.activityObj.ActionID + "/videos/unpublish-all")
          .then(() => {
            this.getVideo();
          })
    },

    makeVisible(VideoID, Publish) {
      if (this.readOnly) return;
      const postData = {
        "Publish": Publish
      }
      apiService
          .post("video/" + VideoID + "/change-publish-state", postData)
          .then(() => {
            this.getVideo();
          })
    },

    handleDg() {
      this.Videos = this.Videos.map((item, index) => {
        if (Number(item.Meta.Clip_Order) !== index + 1) {
          this.changeOrderVideo(index + 1, item.VideoID);
        }
        item.Meta.Clip_Order = index + 1;
        return item;
      })
    },

    changeOrderVideo(order, videoId) {
      const postData = {
        "ClipOrder": order
      }
      apiService
          .post("video/" + videoId + "/change-clip-order", postData)
          .then(() => {})
    },

    isIntegrity(title) {
      return title.trim().toLowerCase().indexOf("integrity") !== -1;
    },

    changeTitle(VideoID, title) {
      if (this.readOnly) return;
      title = title.trim()
      title = this.isIntegrity(title) ? title.substr(0, title.toLowerCase().indexOf("-integrity")) : title + "-Integrity"
      const postData = {
        "videoId": VideoID,
        "title": title,
      }
      apiService
          .post("video/change-title", postData)
          .then(() => {
            this.getVideo();
          })
    },
  },
}
</script>

<style scoped>
.not-visible {
  background-color: rgb(234 84 85 / 40%);
}
.video-content img {
  width: inherit;
  height: inherit;
}
.video-content {
  display: inline-block;
  background-color: grey;
  height: 9rem;
  width: 16rem;
  position: relative;
  top: -0.5rem;
}
.video-content-text-2 {
  position: absolute;
  top: 1.5rem;
  padding-left: 17rem;
}
.video-content-text {
  position: absolute;
  top: 0;
  padding-left: 17rem;
}
</style>