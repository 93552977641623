<template>
  <div>
    <b-row>
      <b-col>
        <h2>Case {{ actionObj.CaseFileNumber }}</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/edit-action/' + actionObj.ActionID"
        >
          <h2>Action {{ actionObj.ActionFileNumber }}</h2>
        </router-link>
      </b-col>
      <b-col v-if="actionObj.ActionTypeName">
        <div class="gray-rectangle">
          {{ actionObj.ActionTypeName }}
        </div>
      </b-col>
      <b-col />
    </b-row>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/' + actionObj.CaseID + '/detail/ACTIONS'"
        >
          <h3 style="text-decoration: underline">
            Case {{ actionObj.CaseFileNumber }}
          </h3>
        </router-link>
      </b-col>
      <b-col>
        <h3>Case State: {{ actionObj.CaseStateCode }}</h3>
      </b-col>
      <b-col>
        <h3>Status: In Progress - Investigator</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>View Activity</h3>
      </b-col>
    </b-row>
    <b-row
      v-if="!activityObj.FlagForApproval"
      class="pt-1"
    >
      <b-col class="text-center">
        <small>
          <strong>
            This Activity has not been approved. When finished, submit it for approval.
          </strong>
        </small>
      </b-col>
    </b-row>
    <b-row
      v-if="activityObj.FlagForApproval"
      class="pt-1"
    >
      <b-col class="text-center">
        <small>
          <strong>
            This Activity has been submitted for approval, and is awaiting review.
          </strong>
        </small>
      </b-col>
    </b-row>
    <b-row
      v-if="!activityObj.FlagForApproval"
      class="p-1"
    >
      <b-col class="text-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="'primary'"
          class="mr-1"
          :disabled="afterClick || (userType === 'Investigator' && Number(UID) !== Number(activityObj.UserID)) || !onLine"
          @click.prevent="submitActivity()"
        >
          Submit Activity for Approval
        </b-button>
      </b-col>
    </b-row>
    <b-row class="p-1">
      <div class="main-tab">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="btnVariant('ACTIVITY_INFO')"
          class="mr-1"
          @click.prevent="openMainTab('ACTIVITY_INFO')"
        >
          Activity Info
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="btnVariant('SYNOPSIS')"
          class="mr-1"
          @click.prevent="openMainTab('SYNOPSIS')"
        >
          Manager Summary
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="btnVariant('TIMELINE')"
          class="mr-1"
          @click.prevent="openMainTab('TIMELINE')"
        >
          Timeline
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="btnVariant('MEDIA')"
          class="mr-1"
          @click.prevent="openMainTab('MEDIA')"
        >
          Media
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="btnVariant('VIDEO')"
          class="mr-1"
          @click.prevent="openMainTab('VIDEO')"
        >
          Video
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          :variant="btnVariant('EXPENSES')"
          class="mr-1"
          @click.prevent="openMainTab('EXPENSES')"
        >
          Expenses
        </b-button>
      </div>
    </b-row>
    <b-row class="">
      <b-col>
        <div class="white-bg-container">
          <ActivityInfoTab
            v-if="mainTab === 'ACTIVITY_INFO'"
            :activity-obj="activityObj"
            :action-obj="actionObj"
          />
          <ActivitySynopsisTab
            v-if="mainTab === 'SYNOPSIS'"
            :activity-obj="activityObj"
            :action-obj="actionObj"
          />
          <ActivityTimelineTab
            v-if="mainTab === 'TIMELINE'"
            :activity-obj="activityObj"
            :action-obj="actionObj"
            @reloadActivity="reloadActivity"
          />
          <ActivityMedia
            v-if="mainTab === 'MEDIA'"
            :activity-obj="activityObj"
            :action-obj="actionObj"
          />
          <ActivityVideo
            v-if="mainTab === 'VIDEO'"
            :activity-obj="activityObj"
            :action-obj="actionObj"
          />
          <ActivityExpensesTab
            v-if="mainTab === 'EXPENSES'"
            :activity-obj="activityObj"
            :action-obj="actionObj"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BRow} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ActivityInfoTab from "@/views/cases/components/ActivityInfoTab";
import ActivitySynopsisTab from "@/views/cases/components/ActivitySynopsisTab";
import ActivityTimelineTab from "@/views/cases/components/ActivityTimelineTab";
import ActivityExpensesTab from "@/views/cases/components/ActivityExpensesTab";
import ActivityVideo from "@/views/cases/components/ActivityVideo";
import ActivityMedia from "@/views/cases/components/ActivityMedia";
import {mapGetters, mapMutations} from "vuex";

const apiService = new APIService();

export default {
  components: {
    ActivityMedia,
    ActivityVideo,
    ActivityExpensesTab,
    ActivityTimelineTab,
    ActivitySynopsisTab,
    ActivityInfoTab,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activityObj: {},
      actionObj: {},
      userType: "",
      UID: "",
      mainTab: "ACTIVITY_INFO",
      afterClick: false,

      onLine: navigator.onLine,
    }
  },
  computed: {
    ...mapGetters({
      getEditMode: "video/getEditMode",
      getOnlineStatus: "offline/getOnlineStatus",
    })
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
  },
  mounted() {
    this.userType = localStorage.getItem("userType");
    this.UID = localStorage.getItem("UID");
    this.setEditMode(false);
  },
  created() {
    this.ActivityID = this.$route.params.activityID;
    const keyTab = this.$route.params.keyTab;
    if (this.ActivityID) {
      this.loadActivity(keyTab);
    }
  },
  methods: {
    ...mapMutations({
      setEditMode: "video/setEditMode",
    }),
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    loadActivity(keyTab = null) {
      apiService
          .get("activity/" + this.ActivityID + "/detail")
          .then(res => {
            this.activityObj = res.data;
            if (res.data.VehicleID) this.getVehicles(res.data.VehicleID);
            apiService
                .get("action/" + this.activityObj.ActionID + "/detail")
                .then(response => {
                  this.actionObj = response.data;
                  if (response.data.ScheduledDate) this.ActivityBeginDate = this.OnSiteArrivalDate = this.OnSiteDepartureDate = this.ActivityEndDate = response.data.ScheduledDate.split('T')[0];
                  if (keyTab) this.mainTab = keyTab;
                })
          })
    },
    getVehicles(VehicleID = null) {
      apiService
          .get("vehicles/list")
          .then(res => {
            this.vehicleOptions = res.data.map(i => ({title: i.Name, value: i.VehicleID}));
            this.vehicleOptions.unshift({title: "N/A", value: 0})
            if (VehicleID) {
              this.Vehicle = this.vehicleOptions.filter(i => i.value === VehicleID);
            }
          })
    },
    submitActivity() {
      if (this.getEditMode) {
        this.showToast('warning', 'top-center', 4000, 'First you have to finish editing');
        return
      }
      this.afterClick = true;
      apiService
          .post("activity/" + this.ActivityID + "/approve")
          .then(res => {
            if (res) {
              this.afterClick = false;
              this.showToast('success', 'top-center', 4000, 'Activity submitted for approval!');
              this.loadActivity();
            }
          })
    },
    btnVariant(tab) {
      return this.mainTab === tab ? "primary" : "none"
    },

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    openMainTab(tab) {
      if (this.isEmpty(this.actionObj) || this.isEmpty(this.activityObj)) return;
      if (this.getEditMode) {
        this.showToast('warning', 'top-center', 4000, 'First you have to finish editing');
        return
      }
      this.mainTab = tab;
    },
    reloadActivity() {
      this.loadActivity();
    }
  },
}
</script>

<style scoped>

</style>