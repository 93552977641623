<template>
  <div>
    <validation-observer ref="timelineRules">
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Add Detail Date"
                :rules="isRequired ? 'required' : ''"
              >
                <b-form-group
                  class="required-data"
                  label="Add Detail Date"
                  label-for="createDate"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="createDate"
                    v-model="CreateDate"
                    type="date"
                    placeholder=""
                    name="createDate"
                    :readonly="readOnly"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small
                    class="text-danger"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                name="Add Detail"
                :rules="isRequired ? 'required' : ''"
              >
                <b-form-group
                  class="required-data"
                  label="Time"
                  label-for="createTime"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="createTime"
                    v-model="CreateTime"
                    type="time"
                    placeholder=""
                    name="createTime"
                    :readonly="readOnly || editableAll"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small
                    class="text-danger"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="pt-1">
          <b-form-group>
            <b-form-checkbox
              id="videoObtained"
              v-model="VideoObtained"
              name="videoObtained"
              :disabled="readOnly || editableAll"
            >
              Video Obtained
            </b-form-checkbox>
            <b-form-checkbox
              id="claimantFirstObserved"
              v-model="ClaimantFirstObserved"
              name="claimantFirstObserved"
              :disabled="readOnly || editableAll"
            >
              Claimant First Observed
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            #default="{ errors }"
            name="Detail"
            :rules="isRequired ? 'required' : ''"
          >
            <b-form-group
              class="required-data"
              label="Detail"
              label-for="createTime"
              :state="errors.length > 0 ? false : null"
            >
              <div
                class="collapse-textarea mb-0"
                :class="{expanded: !isExpanded}"
              >
                <textarea
                  v-model="Synopsis"
                  class="expandable-textarea"
                  :class="[isExpanded ? 'not-expanded' : 'expanded']"
                  contentEditable
                  :state="errors.length > 0 ? false : null"
                  :readonly="readOnly || editableAll"
                />
                <div @click="isExpanded = !isExpanded">
                  <span
                    class="expand-arrow mt-n1"
                    :class="[isExpanded ? 'not-expanded' : 'expanded']"
                  >&#187;</span>
                </div>
              </div>
              <small
                class="text-danger"
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="actionObj.IsLegalHold || (userType === 'Investigator' && Number(UID) !== Number(activityObj.UserID)) || editableAll || loading"
              @click.prevent="addDetail()"
            >
              {{ editable ? "Save" : "Add Detail" }}
            </b-button>
          </div>
        </b-col>
        <b-col />
        <b-col>
          <div
            v-if="!editableAll"
            class="float-right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              :disabled="actionObj.IsLegalHold || (userType === 'Investigator' && Number(UID) !== Number(activityObj.UserID)) || loading || editable || !onLine"
              @click.prevent="editDetails()"
            >
              Edit Details...
            </b-button>
          </div>
          <div
            v-else
            class="d-flex float-right"
          >
            <div class="mr-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                :disabled="loading"
                @click.prevent="cancelEdit()"
              >
                Cancel Edit
              </b-button>
            </div>

            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                :disabled="loading"
                @click.prevent="saveEdit()"
              >
                Save Edit
              </b-button>
            </div>

          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- table -->
    <b-row class="p-1">
      <b-col class="border-top-dark min-height-10 rounded-sm">
        <b-row
          class="bg-light border-left-dark border-right-dark height-50 d-flex align-items-center"
          :class="{'border-bottom-dark': !activityObj.ChildActivities.length}"
        >
          <b-col md="2">
            User
          </b-col>
          <b-col md="3">
            Date & Time
          </b-col>
          <b-col md="1" />
          <b-col>Detail</b-col>
          <b-col md="1">
            Action
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) of activityObj.ChildActivities"
          :key="index"
          class="border-left-dark border-right-dark d-flex align-items-center"
          :class="{'border-bottom-dark': index + 1 === activityObj.ChildActivities.length}"
        >
          <b-col>
            <b-row class="pt-1 border-top">
              <b-col md="2">
                <span>{{ item.UserFullName }}</span>
              </b-col>
              <b-col md="3">
                <span class="text-nowrap">{{ dateFormat(item.Created, item.isOffLineEdited) }}</span>
              </b-col>
              <b-col md="1">
                <span>
                  <img
                    v-if="item.ClaimantFirstObserved && getSkin === 'dark'"
                    class="pb-1"
                    src="../../../assets/images/icons/Observed_dark.png"
                  >
                  <img
                    v-if="item.ClaimantFirstObserved && getSkin === 'light'"
                    class="pb-1"
                    src="../../../assets/images/icons/Observed_light.png"
                  >
                  &nbsp;
                  <img
                    v-if="item.VideoObtained && getSkin === 'dark'"
                    class="pb-1"
                    src="../../../assets/images/icons/Camera_dark.png"
                  >
                  <img
                    v-if="item.VideoObtained && getSkin === 'light'"
                    class="pb-1"
                    src="../../../assets/images/icons/Camera_light.png"
                  >
                </span>
              </b-col>
              <b-col md="5">
                <div
                  v-if="!editableAll"
                  class="overflow-auto"
                >{{ item.Synopsis }}</div>
                <textarea
                  v-else
                  v-model="item.Synopsis"
                  class="expandable-textarea"
                  contentEditable
                />
              </b-col>
              <b-col md="1">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    :disabled="editableAll"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="editDetail(item)">
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="onLine"
                      @click="deleteDetail(item)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      id="modal-delete-timeline"
      ok-variant="danger"
      ok-title="Delete Timeline"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Timeline"
      @ok="confirmDeleteTimeline()"
    >
      <div v-if="timelineToDelete !== null">
        Are you sure you want to delete this Timeline ? This cannot be
        undone.
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BDropdown, BDropdownItem, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {mapGetters, mapMutations} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { required } from "@validations";
import {changeNewItemInCache, checkDatabase, getSpecialID, resolveDaata} from "@core/utils/offlineService";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    activityObj: {
      type: Object,
      default: () => {},
    },
    actionObj: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      CreateDate: "",
      CreateTime: "",
      VideoObtained: false,
      ClaimantFirstObserved: false,
      Synopsis: "",
      ChildActivities: [],

      readOnly: false,
      isExpanded: false,

      editable: false,
      editableAll: false,
      timelineActivitiID: "",
      timelineToDelete: null,
      userType: "",
      UID: "",
      isRequired: true,
      loading: false,
      required,

      onLine: navigator.onLine,
    }
  },
  computed: {
    ...mapGetters({
      getSkin: "appConfig/getSkin",
      getUserType: "auth/getUserType",
      getOnlineStatus: "offline/getOnlineStatus",
    }),
  },
  watch: {
    getOnlineStatus(val) {
      this.onLine = val;
    },
    CreateDate() {
      this.isRequired = true;
    },
    CreateTime() {
      this.isRequired = true;
    },
    Synopsis() {
      this.isRequired = true;
    },
    editableAll(val) {
      this.setEditMode(val)
    },
  },
  mounted() {
    if (this.actionObj.ScheduledDate) this.CreateDate = this.actionObj.ScheduledDate.split("T")[0];
    this.ChildActivities = JSON.parse(JSON.stringify(this.activityObj.ChildActivities));
    this.userType = localStorage.getItem("userType");
    this.UID = localStorage.getItem("UID");
    this.setEditMode(false);
  },
  methods: {
    ...mapMutations({
      setEditMode: "video/setEditMode",
    }),
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    addDetail() {
      this.isRequired = true;
      this.$refs.timelineRules.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          let postData = {
            "Synopsis": this.Synopsis,
            "Created": this.locationDeviation(this.CreateDate, this.CreateTime),
            "VideoObtained": this.VideoObtained,
            "ClaimantFirstObserved": this.ClaimantFirstObserved,
            "ParentActivityID": this.activityObj.ActivityID,
            "timelineID": this.timelineActivitiID,
            "isOffLineEdited": !this.onLine
          }
          if (!this.editable && !this.onLine) {
            postData.timelineID = getSpecialID();
          }
          if (this.editable && !this.onLine) {

            checkDatabase(postData.timelineID)
            .then(hasDatabase => {
              if (hasDatabase) {
                changeNewItemInCache(postData)
                this.updateIfOffline(this.timelineActivitiID)
              } else {
                this.sendDetail(postData)
              }
            })
            .catch(error => {
              console.error('Error', error);
            });
          } else {
            this.sendDetail(postData)
          }
        }
      })
    },

    sendDetail(postData) {
      const path = !this.editable ? "timeline/create" : "timeline/" + this.timelineActivitiID + "/edit"
      apiService
          .post(path, postData)
          .then(res => {
            if (res) {
              this.showToast("success", "top-center", 4000, "Detail added or edited!");
              apiService
                  .get("activity/" + this.activityObj.ActivityID + "/detail")
                  .then(response => {
                    this.loading = false;
                    let newItems = [];
                    if (!this.onLine) {
                      newItems = this.activityObj.ChildActivities.filter(activities => String(activities.ActivityID).substring(0, 3) === 'SID');
                      this.activityObj.ChildActivities = response.data.ChildActivities.filter(activities => newItems.every(item => item.ActivityID !== activities.ActivityID))
                      this.activityObj.ChildActivities = this.activityObj.ChildActivities.concat(newItems);
                    } else {
                      this.activityObj.ChildActivities = response.data.ChildActivities
                    }
                    this.activityObj.ChildActivities.sort((a,b) => (a.Created > b.Created) ? 1 : ((b.Created > a.Created) ? -1 : 0))
                    this.ChildActivities = JSON.parse(JSON.stringify(this.activityObj.ChildActivities));
                    this.clearData();
                  })
            } else {
              this.loading = false;
            }
          })
    },

    updateIfOffline(timelineID) {
      this.activityObj.ChildActivities = this.activityObj.ChildActivities.map(item => {
        if (item.ActivityID === timelineID) {
          item.ClaimantFirstObserved = this.ClaimantFirstObserved;
          item.Synopsis = this.Synopsis;
          item.VideoObtained = this.VideoObtained;
          item.Created =  this.locationDeviation(this.CreateDate, this.CreateTime);
          item.isOffLineEdited = !this.onLine
        }
        return item;
      })
      this.clearData();
    },

    clearData() {
      this.editable = false;
      this.Synopsis = "";
      this.CreateDate = this.actionObj.ScheduledDate.split("T")[0];
      this.CreateTime = "";
      this.VideoObtained = false;
      this.ClaimantFirstObserved = false;
      this.loading = false;
      setTimeout(() => {
        this.isRequired = false;
      })
    },

    editDetail(item) {
      this.editable = true;
      this.timelineActivitiID = item.ActivityID;
      this.Synopsis = item.Synopsis;
      this.CreateDate = item.Created.split("T")[0];
      this.CreateTime = item.Created.split("T")[1].substring(0, 8);
      this.VideoObtained = item.VideoObtained;
      this.ClaimantFirstObserved = item.ClaimantFirstObserved;
    },

    editDetails() {
      this.editableAll = true;
    },

    cancelEdit() {
      this.editableAll = false;
      this.$emit("reloadActivity")
    },

    saveEdit() {
      this.loading = true;
      this.editableAll = false;
      const arrToSave = []

      this.activityObj.ChildActivities.map(item => {
        const comparisonElement = this.ChildActivities.find(i => i.ActivityID === item.ActivityID);
        if (comparisonElement.Synopsis !== item.Synopsis) {
          arrToSave.push(item)
        }
      })
      let item;
      let index = arrToSave.length;

      while (item = arrToSave.pop()) {
        if (item) {
          --index
          this.saveAllByOne(item, index)
        }
      }
    },

    saveAllByOne(item, index) {
      let postData = {
        "Synopsis": item.Synopsis,
        "Created":  item.Created,
        "VideoObtained": item.VideoObtained,
        "ClaimantFirstObserved": item.ClaimantFirstObserved,
        "ParentActivityID": this.activityObj.ActivityID,
        "timelineID": item.ActivityID,
        "isOffLineEdited" : item.isOffLineEdited
      }
      const path = "timeline/" + item.ActivityID + "/edit"
      apiService
          .post(path, postData)
          .then(res => {
            if (res && !index) {
              this.loading = false;
              this.showToast("success", "top-center", 4000, "Details added or edited!");
            }
          })
    },

    deleteDetail(item) {
      this.$bvModal.show("modal-delete-timeline");
      this.timelineToDelete = item;
    },

    confirmDeleteTimeline() {
      apiService.delete("activity/" + this.timelineToDelete.ActivityID)
          .then(res => {
            if (res) {
              this.showToast('success', 'top-center', 4000, 'Timeline delete!');
              this.timelineToDelete = null;
              this.$emit("reloadActivity")
            }
          })
    },

    locationDeviation(date, time) {
      const totalDate = new Date(`${date}T${time}`);
      return new Date(totalDate.getTime() - (totalDate.getTimezoneOffset() * 60000)).toISOString();
    },

    dateFormat(val,  isOffLineEdited = false) {
      let date = new Date(val);
      if (isOffLineEdited) {
        date = new Date(date - (-date.getTimezoneOffset() * 60000));
      }
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      const timeOptions = {hour: '2-digit', minute: '2-digit', second: '2-digit'};
      const Time = date.toLocaleTimeString("en-US", timeOptions).replace(/(.*)\D\d+/, '$1');
      if (date) return date.toLocaleDateString("en-US", options) + " " + Time;
    },
  }
}
</script>

<style scoped>

</style>@/@core/utils/offlineService